import styled from "@emotion/styled";

const InviteContentWrapper = styled.div`
  text-align: center;
  margin-top: 4px;
`;

const InviteContentText = styled.p`
  color: #9c9c9c;
  font-size: 14px;
`;

const JoinContentWrapper = styled.div`
  text-align: center;
  margin-top: 4px;
`;

const JoinContentText = styled.p`
  color: #9c9c9c;
  font-size: 14px;
`;

const ErrorMessage = styled.p`
  color: #f23955;
  font-size: 12px;
  margin: 9px 0;
  text-align: center;
`;

export {
  InviteContentWrapper,
  InviteContentText,
  JoinContentWrapper,
  JoinContentText,
  ErrorMessage,
};
