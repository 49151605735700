import useMediaQuery from "hooks/useMediaQuery";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import HeaderLogo from "assets/images/chat/chat_logo.png";

import { Container, HeaderWrapper, Home, Logo } from "./Header.styled";

function Header({ showHomeButton }) {
  const { isMobile } = useMediaQuery();
  return (
    <Container isMobile={isMobile}>
      <HeaderWrapper>
        {showHomeButton && (
          <Home>
            <Link to="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15"
                fill="none"
              >
                <path d="M7.5 1L1 7.5L7.5 14" stroke="#444444" />
              </svg>
            </Link>
          </Home>
        )}
        <Logo src={HeaderLogo} alt={"ai연애코치"} />
        <div></div>
      </HeaderWrapper>
    </Container>
  );
}

Header.propTypes = {
  showHomeButton: PropTypes.bool,
};

export default Header;
