import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AppWrapper from "App";
import "index.css";
import React from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom/client";
import reportWebVitals from "reportWebVitals";

import theme from "constant/theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

// Kakao 객체가 window 객체에 정의되어 있는지 확인하고 초기화합니다.
if (!window.Kakao.isInitialized()) {
  window.Kakao.init("6ff6b19a2276a5231964239f20c40bb4");
}

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <EmotionThemeProvider theme={{ ...theme }}>
        <MuiThemeProvider theme={{ ...createTheme(theme) }}>
          <CookiesProvider>
            <AppWrapper />
          </CookiesProvider>
        </MuiThemeProvider>
      </EmotionThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
