import styled from "@emotion/styled";

const Wrap = styled.div`
  max-width: 400px;
  background: #fff9f9;
  max-height: 788px;
  height: 100%;
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${(props) => props.theme.device.mobile} {
    /* max-height: 100%; */
    max-width: 100%;
  }
`;

export const LoadingWrap = styled.div`
  max-width: 400px;
  background: #fff9f9;
  max-height: 788px;
  height: 100%;
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${(props) => props.theme.device.mobile} {
    max-width: 100%;
  }
`;

export const LoadingText = styled.div`
  color: #f68092;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 12.923px; /* 92.308% */
  margin-top: 9px;
`;

export const LoadingIcon = styled.div`
  /* HTML: <div class="loader"></div> */

  width: 36px;
  aspect-ratio: 1;
  background: linear-gradient(#f68092 0 0) bottom/100% 0% no-repeat #ccc;
  -webkit-mask:
    radial-gradient(circle at 60% 65%, #000 62%, #0000 65%) top left,
    radial-gradient(circle at 40% 65%, #000 62%, #0000 65%) top right,
    linear-gradient(to bottom left, #000 42%, #0000 43%) bottom left,
    linear-gradient(to bottom right, #000 42%, #0000 43%) bottom right;
  -webkit-mask-size: 50% 50%;
  -webkit-mask-repeat: no-repeat;
  animation: l19 2.2s infinite linear;

  @keyframes l19 {
    90%,
    100% {
      background-size: 100% 100%;
    }
  }
`;

const ChatContainer = styled.div`
  padding: 28px 20px 0;
  background-color: #fff9f9;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    height: 10%;
    background: #e7e7e7;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #bdbdbd;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
`;

const ChatList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SendWrapper = styled.div`
  padding: 21px 21px 30px;
`;

const SendContainer = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-top: 0;
  position: relative;
`;

const UserInput = styled.textarea`
  flex-grow: 1;
  padding: 14px 44px 14px 19.5px;
  border-radius: 8px;
  border: 1px solid #f74f68;
  background: #fff;
  outline: none;
  font-size: 14px;
  resize: none;
  max-height: 91px; /* 최대 높이 설정 (3줄) */
  height: 49px;
  overflow-y: auto; /* 스크롤 바 숨김 */
  font-weight: 400;
  box-shadow: 0px 4px 9.9px 0px rgba(254, 32, 65, 0.2);

  &::placeholder {
    color: #c6c6c6;
  }

  &::-webkit-scrollbar {
    width: 0px; /* 스크롤바의 너비를 0으로 설정하여 보이지 않게 함 */
    background: transparent; /* 배경을 투명하게 설정 */
  }
`;

const SendButton = styled.button`
  position: absolute;
  position: absolute;
  bottom: 5.5px;
  right: 4px;
  width: 39px;
  height: 39px;
  border-radius: 8px;
  background: none;
  border: none;
  cursor: pointer;
`;

const TypingAnimation = styled.div`
  &::after {
    content: "답변을 작성하는 중입니다";
    animation: typing-animation 1.5s infinite steps(3);
    min-width: 160px;
    width: 100%;
    display: inline-block;
  }

  @keyframes typing-animation {
    0% {
      content: "답변을 작성하는 중입니다.";
    }
    33% {
      content: "답변을 작성하는 중입니다..";
    }
    66% {
      content: "답변을 작성하는 중입니다...";
    }
    100% {
      content: "답변을 작성하는 중입니다.";
    }
  }
`;

export {
  Wrap,
  ChatContainer,
  SendContainer,
  UserInput,
  SendButton,
  ChatList,
  SendWrapper,
  TypingAnimation,
};
