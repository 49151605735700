import useMediaQuery from "hooks/useMediaQuery";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import Layout from "components/layout/Layout";
import CodeInputContent from "components/modals/codeModal/CodeInputContent";
import CodeModal from "components/modals/codeModal/CodeModal";
import InviteContent from "components/modals/codeModal/InviteContent";
import ManualModal from "components/modals/manualModal/ManualModal";
import SplashScreen from "components/splashScreen/SplashScreen";

// API 함수 임포트
import { checkRoomCode, createUniqueRoomCode } from "services/api";

import indexLogo from "assets/images/index/index_logo.png";
import TitleImg from "assets/images/index/index_title.png";
import indexTooltip from "assets/images/index/index_tooltip.png";
import indexTooltipIcon from "assets/images/index/index_tooltipIcon.png";
import openGraphImg from "assets/images/share_opengraph_img.png";

import {
  Arrow,
  BtnContainer,
  Container,
  InviteButton,
  JoinButton,
  Logo,
  TextContainer,
  Title,
  TooltipConainer,
  TooltipIcon,
  TooltipText,
  Wrap,
} from "./Index.styled";

function Index() {
  const { isMobile } = useMediaQuery();
  const [open, setOpen] = useState(false);
  const [manualOpen, setManualOpen] = useState(false); // Manual Modal 상태 추가
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [confirmText, setConfirmText] = useState("");
  const [onConfirm, setOnConfirm] = useState(() => () => {});
  const [buttonType, setButtonType] = useState("");
  const [codeValue, setCodeValue] = useState(""); // 상태 추가
  const [inviteCode, setInviteCode] = useState(""); // 초대 코드 상태
  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState(""); // 에러 메시지 상태 추가
  const codeValueRef = useRef(codeValue); // useRef로 상태값 유지
  const [showSplash, setShowSplash] = useState(true);
  const navigate = useNavigate(); // useNavigate 훅 사용
  const splashRef = useRef(null);
  const indexRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    codeValueRef.current = codeValue;
  }, [codeValue]);
  useEffect(() => {
    setIsDisabled(codeValue.length !== 6); // 코드 값이 6자리가 되면 버튼 활성화
  }, [codeValue]);

  const handleOpen = async (title, content, confirmText, onConfirmAction, buttonType) => {
    if (buttonType === "invite") {
      try {
        const code = await createUniqueRoomCode();
        console.log("Fetched Code from Server: ", code);
        setInviteCode(code);
        content = <InviteContent code={code} />;
        setModalTitle(title);
        setModalContent(content);
        setConfirmText(confirmText);
        setButtonType(buttonType); // 버튼 타입 설정
        setOnConfirm(() => () => onConfirmAction(code)); // 초대 코드를 onConfirm으로 전달
        setOpen(true);
      } catch (error) {
        console.error("Failed to fetch unique room code", error);
      }
    } else {
      setModalTitle(title);
      setModalContent(content);
      setConfirmText(confirmText);
      setButtonType(buttonType); // 버튼 타입 설정
      setOnConfirm(() => onConfirmAction);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setCodeValue(""); // 모달이 닫힐 때 value 초기화
    setInviteCode(""); // 초대 코드 초기화
    setIsDisabled(true); // 모달이 닫힐 때 버튼 비활성화
    setError(""); // 에러 메시지 초기화
  };

  const shareToKakao = (code) => {
    console.log("Sharing Code to Kakao: ", code);
    if (window.Kakao && window.Kakao.isInitialized()) {
      window.Kakao.Link.sendDefault({
        objectType: "feed",
        content: {
          title: "[AI 연애코치💘] 연애코치랑 상담하러 가기",
          description: `채팅방 코드: ${code}`,
          imageUrl: `https://ozlovecoach.com/${openGraphImg}`,
          link: {
            mobileWebUrl: "https://ozlovecoach.com/",
            webUrl: "https://ozlovecoach.com/",
          },
        },
        buttons: [
          {
            title: "채팅방 입장하기",
            link: {
              mobileWebUrl: "https://ozlovecoach.com/",
              webUrl: "https://ozlovecoach.com/",
            },
          },
        ],
      });
    } else {
      console.error("Kakao SDK is not initialized");
    }
  };

  const handleInviteConfirm = (code) => {
    console.log("연인 초대하기 확인");
    shareToKakao(code); // 초대 코드 공유 호출
    handleClose();
  };

  const handleCodeInputConfirm = async () => {
    console.log("입력된 코드:", codeValueRef.current); // 최신값 사용

    try {
      const exists = await checkRoomCode(codeValueRef.current);
      if (exists) {
        navigate(`/entrance/${codeValueRef.current}`); // 입력된 코드와 함께 /entrance 경로로 이동
        setCodeValue(""); // 확인 버튼을 눌렀을 때 value 초기화
        handleClose();
      } else {
        setError("유효하지 않은 코드입니다. 다시 입력해주세요.");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError("유효하지 않은 코드입니다. 다시 입력해주세요.");
      } else {
        console.error("Error checking room code", error);
        setError("서버 오류가 발생했습니다. 나중에 다시 시도해주세요.");
      }
    }
  };

  const handleManualOpen = () => {
    setManualOpen(true); // 사용 설명서 모달 열기
  };

  const handleManualClose = () => {
    setManualOpen(false); // 사용 설명서 모달 닫기
  };

  return (
    <>
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <CSSTransition
          in={showSplash}
          timeout={1000}
          classNames="fade"
          unmountOnExit
          nodeRef={splashRef}
        >
          <div ref={splashRef} style={{ position: "absolute", width: "100%", height: "100%" }}>
            <Layout>
              <SplashScreen />
            </Layout>
          </div>
        </CSSTransition>
        <CSSTransition
          in={!showSplash}
          timeout={1000}
          classNames="fade"
          unmountOnExit
          nodeRef={indexRef}
        >
          <div ref={indexRef} style={{ position: "absolute", width: "100%", height: "100%" }}>
            <Layout>
              <Wrap isMobile={isMobile}>
                <Container>
                  <TooltipConainer>
                    <TooltipText src={indexTooltip} alt="연애코치가 처음이라면?" />
                    <TooltipIcon src={indexTooltipIcon} alt="icon" onClick={handleManualOpen} />
                  </TooltipConainer>
                  <Logo src={indexLogo} alt="AI 연애코치에 오신걸 환영합니다" />
                  <TextContainer>
                    <Title src={TitleImg} alt="연애고민이 있으신가요?" />
                    <p>
                      AI 연애코치가 서로의 성향을 분석 해<br />
                      보다 정확하고 합리적인 조언을 해드립니다.
                    </p>
                    <b>궁금한 것을 자유롭게 질문 해 보세요!</b>
                  </TextContainer>

                  <Arrow>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="26"
                      viewBox="0 0 21 26"
                      fill="none"
                    >
                      <path d="M1 1L10.5 8.84783L20 1" stroke="#CD6868" />
                      <path d="M1 8.84766L10.5 16.6955L20 8.84766" stroke="#C55757" />
                      <path d="M1 16.6953L10.5 24.5431L20 16.6953" stroke="#980303" />
                    </svg>
                  </Arrow>
                </Container>
                <BtnContainer>
                  <InviteButton
                    onClick={() =>
                      handleOpen(
                        "채팅방 코드 생성하기",
                        null,
                        "코드 공유하기",
                        handleInviteConfirm,
                        "invite",
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                    >
                      <path d="M1 5H9" stroke="white" strokeWidth="1.66667" strokeLinecap="round" />
                      <path
                        d="M5 1L5 9"
                        stroke="white"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                      />
                    </svg>
                    채팅방 코드 생성하기
                  </InviteButton>
                  <p>코드가 이미 있으시다면?</p>
                  <JoinButton
                    onClick={() =>
                      handleOpen(
                        "채팅방 입장하기",
                        <CodeInputContent
                          value={codeValue}
                          onChange={setCodeValue}
                          error={error}
                        />, // 상태와 onChange 전달
                        "입장하기",
                        handleCodeInputConfirm,
                        "join",
                      )
                    }
                  >
                    채팅방 입장하기
                  </JoinButton>
                </BtnContainer>
                {open && (
                  <CodeModal
                    open={open}
                    handleClose={handleClose}
                    title={modalTitle}
                    confirmText={confirmText}
                    onConfirm={onConfirm}
                    buttonType={buttonType}
                    disabled={buttonType === "join" && isDisabled} // Join 버튼일 때 비활성화 상태 전달
                  >
                    {React.cloneElement(modalContent, { error })} {/* Error 상태 전달 */}
                  </CodeModal>
                )}

                {manualOpen && (
                  <ManualModal open={manualOpen} handleClose={handleManualClose} /> // Manual Modal 추가
                )}
              </Wrap>
            </Layout>
          </div>
        </CSSTransition>
      </div>
    </>
  );
}

export default Index;
