import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { StyledInput } from "./CodeInput.styled";

const CodeInput = ({ color, value = "", readOnly = false, placeholder = "", onChange }) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value.replace(/(.{3})(?=.)/g, "$1 "));
  }, [value]);

  const handleChange = (e) => {
    let newValue = e.target.value;
    newValue = newValue.replace(/[^a-zA-Z0-9]/g, ""); // 대소문자 알파벳과 숫자만 허용
    newValue = newValue.slice(0, 6); // 최대 6자리
    const formattedValue = newValue.replace(/(.{3})(?=.)/g, "$1 "); // 3자리마다 공백 추가

    setInputValue(formattedValue);
    onChange(newValue); // 공백이 제거된 값을 부모 컴포넌트에 전달
  };

  return (
    <StyledInput
      color={color}
      value={inputValue}
      readOnly={readOnly}
      placeholder={placeholder.replace(/(.{3})(?=.)/g, "$1 ")}
      onChange={!readOnly ? handleChange : undefined}
    />
  );
};

CodeInput.propTypes = {
  color: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default CodeInput;
