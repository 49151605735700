const breakpoints = {
  values: {
    mobile: 576,
    tablet: 992,
    laptop: 1440,
  },
};

const device = {
  mobile: `screen and (max-width: ${breakpoints.values.mobile}px)`,
  tablet: `screen and (max-width: ${breakpoints.values.tablet}px)`,
  laptop: `screen and (max-width: ${breakpoints.values.laptop}px)`,
};

const typography = {
  fontFamily: [
    "Pretendard",
    "ui-sans-serif",
    "system-ui",
    "-apple-system",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "Helvetica Neue",
    "Arial",
    "NotoSansKR",
    "Noto Sans",
    "sans-serif",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji",
  ].join(","),
};

const palette = {
  primary: { main: "#FF3D00", light: "#FF3D00", dark: "#FF3D00" },
};

const theme = {
  breakpoints,
  device,
  palette,
  typography,
};

export default theme;
