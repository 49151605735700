import styled from "@emotion/styled";

const Wrap = styled.div`
  max-width: 400px;
  background: #fff;
  max-height: 788px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${(props) => props.theme.device.mobile} {
    /* max-height: 100%; */
    max-width: 100%;
  }
`;

const Container = styled.div`
  padding: 20px 22px;
  height: 100%;
  background: linear-gradient(180deg, #ffc5cf 64.94%, #ff98a7 100%);
  @media ${(props) => props.theme.device.mobile} {
  }
`;

const TooltipConainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

const TooltipText = styled.img`
  width: 159.5px;
`;

const TooltipIcon = styled.img`
  width: 24px;
  cursor: pointer;
`;

const Logo = styled.img`
  max-width: 274px;
  margin: 0 auto;
  margin-top: 1px;
`;

const TextContainer = styled.div`
  text-align: center;
  margin-top: -50px;

  & p {
    color: #980303;
    font-size: 12px;
    margin: 12px 0;
  }

  & b {
    color: #980303;
    font-size: 16px;
    font-weight: 700;
  }
`;

const Title = styled.img`
  width: 190px;
  margin: 0 auto;
`;

const Arrow = styled.div`
  width: 19px;
  margin: 0 auto;
  margin-top: 38px;
`;

const BtnContainer = styled.div`
  width: 100%;
  padding: 44px 20px 32px;
  text-align: center;
  position: relative;
  & p {
    margin-bottom: 14px;
    color: #737373;
    font-size: 13px;
  }
  @media ${(props) => props.theme.device.mobile} {
  }
`;

const InviteButton = styled.button`
  border-radius: 10px;
  background: #f23955;
  box-shadow: 0px 4px 10px 0px rgba(232, 68, 93, 0.33);
  padding: 14px 18px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  gap: 12px;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  &:hover {
  }

  & svg {
    width: 8px;
    height: 8px;
  }

  @media ${(props) => props.theme.device.mobile} {
  }
`;

const JoinButton = styled.button`
  width: 100%;
  padding: 20px 0;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 2px;
  background: #000;
  border: none;
  cursor: pointer;

  &:hover {
  }

  @media ${(props) => props.theme.device.mobile} {
  }
`;

export {
  Wrap,
  Container,
  BtnContainer,
  TooltipConainer,
  TooltipText,
  TooltipIcon,
  InviteButton,
  Logo,
  Title,
  TextContainer,
  JoinButton,
  Arrow,
};
