import useMediaQuery from "hooks/useMediaQuery";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AdPopup from "components/adPopup/AdPopup";
import Message from "components/chat/message/Message";
import Header from "components/header/Header";
import Layout from "components/layout/Layout";

import { createChat, getChatsByRoomAndGender } from "services/api";

import disabledIcon from "assets/images/chat/chat_loading.png";
import sendIcon from "assets/images/chat/chat_send.png";

import {
  ChatContainer,
  ChatList,
  LoadingIcon,
  LoadingText,
  LoadingWrap,
  SendButton,
  SendContainer,
  SendWrapper,
  TypingAnimation,
  UserInput,
  Wrap,
} from "./Chat.styled";

function Chat() {
  const { code, gender } = useParams(); // 경로 파라미터에서 코드와 성별 값 가져옴
  const { isMobile } = useMediaQuery();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showAd, setShowAd] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true); // 초기 로딩 상태 추가
  const [minLoadingTimeReached, setMinLoadingTimeReached] = useState(false); // 최소 로딩 시간 상태 추가
  const inputRef = useRef(null);
  const messageEndRef = useRef(null);
  const [messages, setMessages] = useState([
    // 초기 메시지 설정
    {
      text: "안녕하세요, 오즈코딩스쿨 AI 연애코치입니다 :)<br> 어떤 고민이 있으신가요?",
      isUser: false,
    },
  ]);

  // 최소 2초 동안 로딩 화면 표시
  useEffect(() => {
    const timer = setTimeout(() => {
      setMinLoadingTimeReached(true);
      if (!isInitialLoading) {
        setIsInitialLoading(false);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, []); // 빈 배열로 설정하여 컴포넌트가 마운트될 때만 실행

  // 채팅방의 기존 메시지를 불러오는 함수
  useEffect(() => {
    const fetchChatMessages = async () => {
      try {
        const chats = await getChatsByRoomAndGender(code, gender);
        const formattedChats = chats.flatMap((chat) => [
          { text: chat.question, isUser: true },
          { text: chat.answer, isUser: false },
        ]);
        setMessages((prevMessages) => [
          prevMessages[0], // 초기 메시지를 유지
          ...formattedChats,
        ]);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          navigate("/", { replace: true });
        } else {
          console.error("Failed to fetch chat messages:", error);
        }
      } finally {
        if (minLoadingTimeReached) {
          setIsInitialLoading(false);
        }
      }
    };

    fetchChatMessages();
  }, [code, gender, minLoadingTimeReached]); // 필요한 의존성만 추가

  useEffect(() => {
    if (!isInitialLoading) {
      setTimeout(() => {
        messageEndRef.current?.scrollIntoView({ behavior: "auto" });
      }, 0); // 초기 로딩 후 스크롤 이동
    }
  }, [isInitialLoading]);

  const handleInputChange = (e) => {
    const input = e.target;
    input.style.height = "auto"; // 높이를 초기화
    input.style.height = `${Math.min(input.scrollHeight, 91)}px`; // 최대 3줄 높이로 제한
    setInputValue(e.target.value);
  };

  const sendMessage = async () => {
    if (inputValue.trim()) {
      const userMessage = { text: inputValue, isUser: true };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setInputValue("");
      inputRef.current.style.height = "49px"; // 메시지 전송 후 높이 초기화

      setIsLoading(true);
      const loadingMessage = { text: "답변을 작성하는 중입니다", isUser: false, isLoading: true };
      setMessages((prevMessages) => [...prevMessages, loadingMessage]);
      scrollToBottom(); // 로딩 메시지가 뜨기 전 스크롤 하단으로 이동

      try {
        const response = await createChat(code, gender, inputValue);
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.isLoading ? { text: response.answer, isUser: false } : msg,
          ),
        );
      } catch (error) {
        console.error("Failed to send message to server:", error);
        setMessages((prevMessages) => prevMessages.filter((msg) => !msg.isLoading)); // 에러 발생 시 로딩 메시지 제거
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: "메시지 전송에 실패했습니다. 다시 시도해주세요.", isUser: false },
        ]);
      } finally {
        setIsLoading(false);
        scrollToBottom(); // 실제 답변이 오면 스크롤 하단으로 이동
      }
    }
  };

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const closeAd = () => {
    setShowAd(false);
  };

  useEffect(() => {
    if (!isInitialLoading) {
      scrollToBottom();
    }
  }, [messages]);

  if (isInitialLoading) {
    return (
      <Layout>
        <LoadingWrap isMobile={isMobile}>
          <LoadingIcon></LoadingIcon>
          <LoadingText>loading..</LoadingText>
        </LoadingWrap>
      </Layout>
    );
  }

  return (
    <Layout>
      <Wrap isMobile={isMobile}>
        <Header showHomeButton={true} />
        {showAd && <AdPopup onClose={closeAd} />}
        <ChatContainer>
          <ChatList>
            {messages.map((msg, index) => (
              <Message
                key={index}
                text={msg.isLoading ? <TypingAnimation /> : msg.text}
                isUser={msg.isUser}
              />
            ))}
            <div ref={messageEndRef} />
          </ChatList>
        </ChatContainer>
        <SendWrapper>
          <SendContainer>
            <UserInput
              ref={inputRef}
              id="userInput"
              placeholder={
                isLoading ? "답변은 최대 1분 정도 소요될 수 있습니다." : "자유롭게 질문해보세요."
              }
              rows="1"
              value={inputValue}
              onChange={handleInputChange}
              disabled={isLoading}
            />
            <SendButton onClick={sendMessage} disabled={!inputValue.trim() || isLoading}>
              <span id="sendButtonText">
                <img src={!inputValue.trim() || isLoading ? disabledIcon : sendIcon} alt="send" />
              </span>
            </SendButton>
          </SendContainer>
        </SendWrapper>
      </Wrap>
    </Layout>
  );
}

export default Chat;
