import styled from "@emotion/styled";

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isUser ? "flex-end" : "flex-start")};
  gap: 10px;
`;

const MessageBubble = styled.div`
  border-radius: 20px;
  border: ${(props) => (props.isUser ? "1px solid #EFEFEF" : "1px solid #FFEDED")};
  background: ${(props) => (props.isUser ? "#282828" : "#fff")};
  color: ${(props) => (props.isUser ? "#fff" : "#000")};
  padding: 12px 16px;
  max-width: 90%;
  line-height: 136%;
`;

const AvartarWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-end;
`;

const Avatar = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 9px;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CoachName = styled.div`
  color: #cf7b87;
  font-size: 12px;
  line-height: 136%; /* 16.32px */
`;

export { MessageWrapper, MessageBubble, Avatar, AvartarWrapper, CoachName };
