// src/components/modals/codeModal/InviteContent.jsx
import PropTypes from "prop-types";
import React from "react";

import CodeInput from "components/input/codeInput/CodeInput";

import { ErrorMessage, InviteContentText, InviteContentWrapper } from "./CodeModal.styled";

const InviteContent = ({ code }) => {
  return (
    <div>
      <InviteContentWrapper>
        <InviteContentText>코드를 공유해서 상대방을 초대해보세요.</InviteContentText>
      </InviteContentWrapper>
      <CodeInput color="#000" value={code} readOnly />
      <ErrorMessage>
        <span>&nbsp;</span>
      </ErrorMessage>
    </div>
  );
};

InviteContent.propTypes = {
  code: PropTypes.string.isRequired,
};

export default InviteContent;
