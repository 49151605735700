// src/components/SplashScreen.jsx
import React from "react";

import splashImage from "assets/images/index/splash_img.png";
import splashLogo from "assets/images/index/splash_logo.png";

import { Container, SplashImg, SplashLogo, Wrap } from "./SplashScreen.styled";

function SplashScreen() {
  return (
    <Wrap>
      <Container>
        <SplashImg src={splashImage} alt="Splash Image" />
        <SplashLogo src={splashLogo} alt="Splash Logo" />
      </Container>
    </Wrap>
  );
}

export default SplashScreen;
