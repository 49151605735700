import useMediaQuery from "hooks/useMediaQuery";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Layout from "components/layout/Layout";

// API 함수 임포트
import { checkRoomCode } from "services/api";

import {
  BtnContainer,
  Container,
  GenderBtn,
  GenderContainer,
  NextBtn,
  Title,
  Wrap,
} from "./Entrance.styled";

const Entrance = () => {
  const navigate = useNavigate();
  const { code } = useParams(); // URL에서 코드 가져오기
  const { isMobile } = useMediaQuery();
  const [selectedGender, setSelectedGender] = useState(""); // 선택된 성별 상태 추가
  const [error, setError] = useState(""); // 에러 메시지 상태 추가
  const [loading, setLoading] = useState(false); // 로딩 상태 추가

  useEffect(() => {
    const verifyRoomCode = async () => {
      try {
        const exists = await checkRoomCode(code);
        if (!exists) {
          navigate("/", { replace: true });
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          navigate("/", { replace: true });
        } else {
          console.error("Failed to verify room code", error);
        }
      }
    };

    verifyRoomCode();
  }, [code, navigate]);

  const handleSelectGender = (gender) => {
    setSelectedGender(gender); // 성별 선택 시 상태 업데이트
  };

  const handleNextClick = () => {
    if (selectedGender) {
      navigate(`/chat/${code}/${selectedGender}`);
    }
  };

  return (
    <Layout>
      <Wrap isMobile={isMobile}>
        <Container>
          <Title>
            자신의 성별을 선택하고
            <br />
            다음 버튼을 눌러주세요.
          </Title>
          <GenderContainer>
            <GenderBtn
              onClick={() => handleSelectGender("M")}
              isSelected={selectedGender === "M"}
              gender="male"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="54"
                viewBox="0 0 22 54"
                fill="none"
              >
                <path
                  d="M6.30641 4.08697C6.30641 1.8543 8.24662 0.0361328 10.6309 0.0361328C13.0152 0.0361328 14.9554 1.85295 14.9554 4.08697C14.9554 6.32098 13.0152 8.1378 10.6309 8.1378C8.24662 8.1378 6.30641 6.32098 6.30641 4.08697ZM21.259 28.5696C21.259 29.5144 20.4383 30.2828 19.4294 30.2828C18.4205 30.2828 17.5999 29.5144 17.5999 28.5696V17.2246C17.5999 16.9258 17.3412 16.6836 17.0221 16.6836C16.7031 16.6836 16.4444 16.9258 16.4444 17.2246V50.5854C16.4444 51.938 15.2702 53.0375 13.8258 53.0375C12.3814 53.0375 11.2072 51.938 11.2072 50.5854V30.8238C11.2072 30.5251 10.9485 30.2828 10.6295 30.2828C10.3104 30.2828 10.0517 30.5251 10.0517 30.8238V50.5854C10.0517 51.938 8.87755 53.0375 7.43317 53.0375C5.98879 53.0375 4.8146 51.938 4.8146 50.5854V17.2246C4.8146 16.9258 4.55591 16.6836 4.23685 16.6836C3.91779 16.6836 3.6591 16.9258 3.6591 17.2246V28.5696C3.6591 29.5144 2.83846 30.2828 1.82955 30.2828C0.820637 30.2828 0 29.5144 0 28.5696V15.5397C0 12.657 2.50503 10.3113 5.5835 10.3113H15.6726C18.7511 10.3113 21.2561 12.657 21.2561 15.5397V28.5696H21.259Z"
                  fill="white"
                />
              </svg>
              남성
            </GenderBtn>
            <GenderBtn
              onClick={() => handleSelectGender("F")}
              isSelected={selectedGender === "F"}
              gender="female"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="54"
                viewBox="0 0 26 54"
                fill="none"
              >
                <path
                  d="M8.33649 4.08697C8.33649 1.85295 10.2767 0.0361328 12.6624 0.0361328C15.0482 0.0361328 16.987 1.85295 16.987 4.08697C16.987 6.32098 15.0468 8.1378 12.6624 8.1378C10.2781 8.1378 8.33793 6.32098 8.33793 4.08697H8.33649ZM25.1086 27.3921C24.8843 27.7837 24.5121 28.073 24.0594 28.2063C23.5909 28.3449 23.0965 28.3005 22.6639 28.0838C22.2313 27.8671 21.918 27.5038 21.7829 27.0623L18.325 15.8478C18.233 15.5477 17.8981 15.3755 17.5777 15.4616C17.2572 15.5477 17.0732 15.8613 17.1652 16.1614L20.6231 27.3773L23.1798 35.6687H17.8809C17.5475 35.6687 17.2773 35.9217 17.2773 36.2339V51.1359C17.2773 52.1721 16.3776 53.0146 15.271 53.0146C14.1643 53.0146 13.2646 52.1721 13.2646 51.1359V36.2339C13.2646 35.9217 12.9944 35.6687 12.661 35.6687C12.3276 35.6687 12.0574 35.9217 12.0574 36.2339V51.1359C12.0574 52.1721 11.1577 53.0146 10.0511 53.0146C8.94442 53.0146 8.04474 52.1721 8.04474 51.1359V36.2339C8.04474 35.9217 7.77455 35.6687 7.44112 35.6687H2.14362L4.70039 27.3773L8.15828 16.1614C8.25026 15.8613 8.0663 15.5477 7.7458 15.4616C7.42531 15.3755 7.09044 15.5477 6.99846 15.8478L3.54057 27.0623C3.40404 27.5038 3.09217 27.8658 2.65957 28.0838C2.22698 28.3005 1.73114 28.3449 1.26406 28.2063C0.811339 28.073 0.439106 27.7837 0.214903 27.3921C-0.0092991 27.0004 -0.0596009 26.5496 0.0711838 26.1243L3.9976 13.4025C4.27641 12.4995 4.84698 11.723 5.65037 11.1591C6.45233 10.5939 7.40662 10.2951 8.41122 10.2951H16.9151C17.9183 10.2951 18.874 10.5939 19.676 11.1591C20.4779 11.7243 21.0499 12.4995 21.3287 13.4025L25.2551 26.1243C25.3874 26.5509 25.3356 27.0018 25.1114 27.3921H25.1086Z"
                  fill="white"
                />
              </svg>
              여성
            </GenderBtn>
          </GenderContainer>
        </Container>
        <BtnContainer>
          <NextBtn onClick={handleNextClick} disabled={!selectedGender}>
            다음
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
            >
              <path d="M1 1L5 5L1 9" />
            </svg>
          </NextBtn>
        </BtnContainer>
      </Wrap>
    </Layout>
  );
};

export default Entrance;
