// src/components/SplashScreen.styled.js
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const moveUpAndShrink = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -82%) scale(0.6);
    opacity: 0.7;
  }
`;

export const Wrap = styled.div`
  max-width: 400px;
  background: #ffa8b5;
  max-height: 788px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transition: opacity 500ms ease-in-out; */

  @media ${(props) => props.theme.device.mobile} {
    max-width: 100%;
  }
`;

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SplashImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  width: 276px;
  animation: ${moveUpAndShrink} 1.6s 0.6s forwards;

  @media ${(props) => props.theme.device.mobile} {
  }
`;

export const SplashLogo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  width: 218px;
  opacity: 0;
  animation: ${fadeIn} 1.6s 0.8s forwards;

  @media ${(props) => props.theme.device.mobile} {
  }
`;
