import axios from "axios";

// Axios 인스턴스 생성
const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
  headers: {
    "Content-Type": "application/json",
  },
});

// 방 코드 생성 API 함수
export const createUniqueRoomCode = async () => {
  try {
    const response = await api.get("/rooms/unique-room-code/");
    return response.data.room_code;
  } catch (error) {
    console.error("Failed to create unique room code", error);
    throw error;
  }
};

// 방 코드 확인 API 함수
export const checkRoomCode = async (room_code) => {
  try {
    const response = await api.post("/rooms/check-room-code/", { room_code });
    return response.data.exists;
  } catch (error) {
    console.error("Failed to check room code", error);
    throw error;
  }
};

// 채팅 생성 API 함수
export const createChat = async (room_code, gender, question) => {
  try {
    const response = await api.post("/chats/create-chat/", { room_code, gender, question });
    return response.data;
  } catch (error) {
    console.error("Failed to create chat", error);
    throw error;
  }
};

// 방 코드와 성별에 따른 채팅 조회 API 함수
export const getChatsByRoomAndGender = async (room_code, gender) => {
  try {
    const response = await api.post("/rooms/chats-by-room-and-gender/", { room_code, gender });
    return response.data.chats;
  } catch (error) {
    console.error("Failed to get chats by room and gender", error);
    throw error;
  }
};
