import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import AiImg from "assets/images/manual/manual_ai.png";
import genderFemaleImg from "assets/images/manual/manual_female.png";
import genderMaleImg from "assets/images/manual/manual_male.png";
import modalImg2 from "assets/images/manual/manual_modal2.png";
import modalImg3 from "assets/images/manual/manual_modal3.png";
import modalImg from "assets/images/manual/manual_modal.png";
import shareImg from "assets/images/manual/manual_share.png";

import {
  StepAiImg,
  StepContent,
  StepGenderImg,
  StepModalImg,
  StepShareImg,
  StepText,
  StepTitle,
} from "./ManualModal.styled";

const steps = [
  {
    title: "STEP1.",
    subtitle: "당신의 연인을 초대하세요",
    content: (
      <StepContent>
        <StepTitle>1. 연인초대</StepTitle>
        <div
          style={{
            display: "inline-block",
            background: "#F23955",
            color: "#fff",
            border: "none",
            padding: "14px 18px",
            borderRadius: "10px",
            boxShadow: "0px 4px 10px 0px rgba(232, 68, 93, 0.33)",
            fontSize: "14px",
            fontWeight: "700",
          }}
        >
          <span style={{ marginRight: "12px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
            >
              <path d="M1 5H9" stroke="white" strokeWidth="1.66667" strokeLinecap="round" />
              <path d="M5 1L5 9" stroke="white" strokeWidth="1.66667" strokeLinecap="round" />
            </svg>
          </span>
          채팅방 코드 생성하기
        </div>
        <StepText>&apos;채팅방 코드 생성하기&apos;를 누르세요</StepText>

        <StepModalImg src={modalImg} alt="img" />

        <StepText>
          &apos;채팅방 코드 생성하기&apos;를 누르면
          <br /> 방을 찾아 들어갈 수 있는 코드가 생성돼요!
        </StepText>
        <div
          style={{
            borderTop: "1px solid #E5E5E5",
            paddingTop: "40px",
            marginTop: "40px",
          }}
        >
          <StepTitle>2. 공유하기</StepTitle>

          <StepModalImg src={modalImg2} alt="img" />

          <StepText>
            &apos;공유하기&apos;를 눌러
            <br /> 카카오톡으로 공유할 수 있어요!
          </StepText>
        </div>
      </StepContent>
    ),
  },
  {
    title: "STEP2.",
    subtitle: "연인과 함께 입장하세요",
    content: (
      <StepContent>
        <StepTitle>1. 채팅방 입장하기</StepTitle>
        <div
          style={{
            background: "#000",
            color: "#fff",
            border: "none",
            fontSize: "14px",
            fontWeight: "700",
            padding: "14px 0",
            borderRadius: "2px",
            width: "256px",
            margin: "0 auto",
          }}
        >
          채팅방 입장하기
        </div>
        <StepText>
          코드를 받았다면 &apos;채팅방 입장하기&apos;로
          <br /> 코드를 입력해 연인과 입장해요
        </StepText>
        <div
          style={{
            background: "#FFEAEE",
            padding: "16px 23px 26px",
            marginTop: "20px",
          }}
        >
          <StepText style={{ color: "#FF4863", fontWeight: "700", fontSize: "14px" }}>
            ⚠️ 혹시 코드를 잊으셨다면?
          </StepText>

          <div
            style={{
              marginTop: "14px",
            }}
          >
            <StepShareImg src={shareImg} alt="img" />
          </div>

          <StepText style={{ fontSize: "12px" }}>
            연인에게 보냈던 링크와 함께 코드를 보내드렸어요!
          </StepText>
        </div>

        <div
          style={{
            borderTop: "1px solid #E5E5E5",
            paddingTop: "40px",
          }}
        >
          <StepTitle>2. 코드 입력하기</StepTitle>
          <StepModalImg src={modalImg3} alt="img" />
          <StepText>코드를 정확히 입력 후 &apos;입장하기&apos;를 눌러 입장합니다.</StepText>
        </div>
      </StepContent>
    ),
  },
  {
    title: "STEP3.",
    subtitle: "AI 연애코치에게 말해보세요",
    content: (
      <StepContent>
        <StepTitle>1. 프로필을 골라주세요</StepTitle>
        <div
          style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}
        >
          <StepGenderImg src={genderMaleImg} alt="남성" />
          <StepGenderImg src={genderFemaleImg} alt="여성" />
        </div>
        <StepText>프로필을 골라 채팅방에 입장합니다.</StepText>

        <div
          style={{
            borderTop: "1px solid #E5E5E5",
            paddingTop: "40px",
            marginTop: "30px",
          }}
        >
          <StepTitle>2. AI 연애 코치에게 물어보세요</StepTitle>
          <StepAiImg src={AiImg} alt="오즈코딩스쿨 AI 연애코치" />
          <StepText>
            서로에게 하기 어려웠던 말들!
            <br /> AI와 함께 연애고민을 상담하세요.
            <br /> AI가 서로의 말을 듣고
            <br /> 이를 종합 분석하여 맞춤형 연애 조언을 제공합니다.
          </StepText>
        </div>
      </StepContent>
    ),
  },
];

const ManualModal = ({ open, handleClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const dialogContentRef = useRef(null);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  useEffect(() => {
    if (dialogContentRef.current) {
      dialogContentRef.current.scrollTop = 0;
    }
  }, [activeStep]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "320px", // 모달 너비 설정
          width: "100%",
          borderRadius: "14px",
          height: "489px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontWeight: "700",
          fontSize: "12px",
          color: "#B4B4B4",
          padding: "46px 0 16px",
          lineHeight: "140%",
        }}
      >
        {steps[activeStep].title}
        <b style={{ color: "#000", fontSize: "16px", marginTop: "5px" }}>
          {steps[activeStep].subtitle}
        </b>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#B1B1B1",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        ref={dialogContentRef}
        sx={{
          background: "#F2F2F2",
          textAlign: "center",
          padding: "40px 0 30px !important",
        }}
      >
        {steps[activeStep].content}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
          padding: "21px 20px 17px",
        }}
      >
        <Button
          onClick={handleBack}
          disabled={activeStep === 0}
          startIcon={<ChevronLeftIcon />}
          sx={{
            padding: "10px 20px",
            color: activeStep === 0 ? "#BCBCBC" : "#000",
            transition: "none !important", // 애니메이션 제거
            backgroundColor: "transparent !important", // 배경색 제거
            boxShadow: "none !important", // 그림자 제거
            "& .MuiButton-startIcon": {
              marginRight: "4px", // 아이콘과 텍스트 간격 조정
            },
            "&:hover": {
              backgroundColor: "#f3f3f3",
              transition: "none !important", // 애니메이션 제거
            },
            "&:active": {
              backgroundColor: "transparent !important", // 클릭 시 배경색 제거
            },
          }}
        >
          이전
        </Button>
        <div>
          {steps.map((step, index) => (
            <span
              key={index}
              style={{
                height: "6px",
                width: "6px",
                margin: "0 2.5px",
                backgroundColor: index === activeStep ? "#F23955" : "#D9D9D9",
                borderRadius: "50%",
                display: "inline-block",
              }}
            />
          ))}
        </div>
        <Button
          onClick={handleNext}
          disabled={activeStep === steps.length - 1}
          endIcon={<ChevronRightIcon />}
          sx={{
            padding: "10px 20px",
            marginLeft: "0 !important",
            color: activeStep === steps.length - 1 ? "#BCBCBC" : "#000",
            transition: "none !important", // 애니메이션 제거
            backgroundColor: "transparent !important", // 배경색 제거
            boxShadow: "none !important", // 그림자 제거
            "& .MuiButton-endIcon": {
              marginLeft: "4px", // 아이콘과 텍스트 간격 조정
            },
            "&:hover": {
              backgroundColor: "#f3f3f3",
              transition: "none !important", // 애니메이션 제거
            },
            "&:active": {
              backgroundColor: "transparent !important", // 클릭 시 배경색 제거
            },
          }}
        >
          다음
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ManualModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ManualModal;
