import styled from "@emotion/styled";

const Wrap = styled.div`
  max-width: 400px;
  background: #fff;
  max-height: 788px;
  height: 100%;
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${(props) => props.theme.device.mobile} {
    /* max-height: 100%; */
    max-width: 100%;
  }
`;

const Container = styled.div`
  height: 100%;
  padding: 27px 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  color: #2f2f2f;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
`;

const GenderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 27px;
`;

const GenderBtn = styled.button`
  border-radius: 16px;
  background: ${(props) =>
    props.isSelected ? (props.gender === "male" ? "#3A65FF" : "#F8546C") : "#D7D7D7"};
  padding: 31px 0 16px;
  width: 140px;
  height: 140px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  transition:
    transform 0.3s,
    background 0.3s; /* 트랜지션 추가 */

  &:hover {
    transform: scale(1.05); /* 호버 시 크기 증가 */
    background: ${(props) =>
      props.isSelected
        ? props.gender === "male"
          ? "#3A65FF"
          : "#F8546C"
        : "#D7D7D7"}; /* 배경색 변경 */
  }
`;

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 27px 20px;

  @media ${(props) => props.theme.device.mobile} {
  }
`;

const NextBtn = styled.button`
  padding: 10px 20px;
  color: ${(props) => (props.disabled ? "#C3C3C3" : "#000")};
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 5px;
  border: none;
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  & svg {
    stroke: ${(props) => (props.disabled ? "#C3C3C3" : "#000")};
  }
`;

export { Wrap, Container, BtnContainer, GenderContainer, GenderBtn, Title, NextBtn };
