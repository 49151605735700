import PropTypes from "prop-types";
import React from "react";

import coachProfile from "assets/images/chat/chat_coach.png";

import { AvartarWrapper, Avatar, CoachName, MessageBubble, MessageWrapper } from "./Message.styled";

const Message = ({ text, isUser }) => (
  <MessageWrapper isUser={isUser}>
    {!isUser && (
      <AvartarWrapper>
        <Avatar isUser={isUser}>
          <img src={coachProfile} alt="avatar" />
        </Avatar>
        <CoachName>AI 연애코치</CoachName>
      </AvartarWrapper>
    )}
    <MessageBubble isUser={isUser}>
      {typeof text === "string" ? (
        <div dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, "<br>") }} />
      ) : (
        text
      )}
    </MessageBubble>
  </MessageWrapper>
);

Message.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isUser: PropTypes.bool.isRequired,
};

export default Message;
