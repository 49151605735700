import { useMediaQuery as useMediaQueryForMui, useTheme as useThemeForMui } from "@mui/material";

const useMediaQuery = () => {
  const { breakpoints } = useThemeForMui();

  const isMobile = useMediaQueryForMui(breakpoints.down("mobile"));

  const isLargerThanMobile = useMediaQueryForMui(breakpoints.up("mobile"));
  const isSmallerThanTablet = useMediaQueryForMui(breakpoints.down("tablet"));
  let isTablet = isLargerThanMobile && isSmallerThanTablet;

  const isLaptop = useMediaQueryForMui(breakpoints.up("tablet"));

  return { isMobile, isTablet, isLaptop };
};

export default useMediaQuery;
