import styled from "@emotion/styled";

const Container = styled.div`
  background: #fff;
  border-bottom: 1px solid #f1f1f1;
  padding: 12px 16px 12px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
`;

const Home = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);

  & a {
    width: 28.5px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Logo = styled.img`
  width: 80px; /* 필요에 따라 로고 크기 조정 */
  height: auto;
`;

export { Container, HeaderWrapper, Home, Logo };
