import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Chat from "pages/chat/Chat";
import Entrance from "pages/entrance/Entrance";
import Index from "pages/index/Index";

if (process.env.NODE_ENV === "production") {
  // eslint-disable-next-line no-global-assign
  console = window.console || {};
  console.log = function no_console() {};
  console.warn = function no_console() {};
  console.error = function () {};
}
console.log("domain", process.env.REACT_APP_DOMAIN);

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/entrance/:code" element={<Entrance />} />
        <Route path="/chat/:code/:gender" element={<Chat />} />
        <Route path="*" element={<Navigate to="/" replace />} />{" "}
        {/* 없는 페이지로 접근 시 리다이렉트 */}
      </Routes>
    </div>
  );
}

export default function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
