import PropTypes from "prop-types";
import React, { useEffect } from "react";

const Layout = ({ children }) => {
  useEffect(() => {
    const setVh = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    setVh();
    window.addEventListener("resize", setVh);
    window.addEventListener("orientationchange", setVh);

    // input, textarea focus/blur 이벤트 처리
    const elements = document.querySelectorAll("input, textarea");
    elements.forEach((element) => {
      element.addEventListener("focus", setVh);
      element.addEventListener("blur", setVh);
    });

    return () => {
      window.removeEventListener("resize", setVh);
      window.removeEventListener("orientationchange", setVh);
      elements.forEach((element) => {
        element.removeEventListener("focus", setVh);
        element.removeEventListener("blur", setVh);
      });
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(var(--vh, 1vh) * 100)",
        justifyContent: "center",
        alignItems: "center",
        background: "#f1f1f1",
      }}
    >
      {children}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
