import styled from "@emotion/styled";

export const StepTitle = styled.h4`
  color: #313131;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
`;

export const StepContent = styled.div``;

export const StepText = styled.p`
  color: #686868;
  text-align: center;
  font-size: 13px;
  margin-top: 14px;
`;

export const StepModalImg = styled.img`
  width: 185px;
  margin: 0 auto;
  margin-top: 20px;
  filter: drop-shadow(0px 0px 18.7px rgba(0, 0, 0, 0.07));
`;

export const StepGenderImg = styled.img`
  width: 100px;
`;

export const StepAiImg = styled.img`
  width: 282px;
  margin: 0 auto;
  margin-top: 20px;
`;

export const StepShareImg = styled.img`
  width: 178px;
  margin: 0 auto;
  margin-top: 20px;
`;
