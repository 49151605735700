import styled from "@emotion/styled";

const StyledInput = styled.input`
  border-radius: 10px;
  background: #f1f1f1;
  margin-top: 16px;
  padding: 10px;
  text-align: center;
  font-size: 32px;
  color: ${(props) => (props.readOnly ? props.color : "#000")};
  font-weight: 700;
  border: none;
  outline: none;
  width: 100%;
  letter-spacing: 4px;

  ::placeholder {
    color: #dcdcdc;
  }

  @media ${(props) => props.theme.device.mobile} {
  }
`;

export { StyledInput };
