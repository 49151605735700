// src/components/modals/codeModal/CodeInputContent.jsx
import PropTypes from "prop-types";
import React from "react";

import CodeInput from "components/input/codeInput/CodeInput";

import { ErrorMessage, JoinContentText, JoinContentWrapper } from "./CodeModal.styled";

const CodeInputContent = ({ value, onChange, error }) => (
  <div>
    <JoinContentWrapper>
      <JoinContentText>코드를 입력하고,채팅방에 입장하세요.</JoinContentText>
    </JoinContentWrapper>
    <CodeInput color="#000" placeholder="000000" value={value} onChange={onChange} />
    <ErrorMessage>{error || <span>&nbsp;</span>}</ErrorMessage>
  </div>
);

CodeInputContent.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default CodeInputContent;
