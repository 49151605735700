import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const CodeModal = ({
  open,
  handleClose,
  title,
  children,
  confirmText,
  onConfirm,
  buttonType,
  disabled,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "320px", // 모달 너비 설정
          width: "100%",
          borderRadius: "14px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontWeight: "700",
          fontSize: "18px",
          padding: "44px 25px 0",
        }}
      >
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#B1B1B1",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "0 25px 37px",
        }}
      >
        {children}
        <DialogActions
          sx={{
            justifyContent: "center",
            padding: "0",
          }}
        >
          <Button
            onClick={onConfirm}
            disabled={disabled}
            sx={{
              backgroundColor: disabled ? "#D7D7D7" : buttonType === "invite" ? "#F23955" : "#000",
              color: disabled ? "#FFF !important" : "#FFF",
              "&.Mui-disabled": {
                color: "#FFF !important",
              },
              "&:hover": {
                backgroundColor: disabled
                  ? "#D7D7D7"
                  : buttonType === "invite"
                    ? "#F23955"
                    : "#000",
              },
              padding: "15px 0",
              width: "100%",
              fontWeight: "700",
              fontSize: "14px",
              borderRadius: "4px",
            }}
          >
            {confirmText}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

CodeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  confirmText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  buttonType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default CodeModal;
