import PropTypes from "prop-types";
import React, { useState } from "react";

import adImage from "assets/images/chat_opengraph_img.png";

import { AdContent, AdImage, AdPopupContainer, AdText, CloseButton } from "./AdPopup.styled";

const AdPopup = ({ onClose }) => {
  const handleButtonClick = () => {
    window.location.href = "https://ozcodingschool.com/ozcoding/lovecoachseminar";
  };

  const handleImageClick = () => {
    window.location.href = "https://ozcodingschool.com/ozcoding/lovecoachseminar";
  };

  return (
    <AdPopupContainer>
      <AdContent>
        <AdImage src={adImage} alt="Ad" onClick={handleImageClick} />
        <AdText>
          <p>이 세미나로 만들었어요!</p>
          <h2>코딩, AI 왕초보도 1시간만에 끝내는 나만의 연애코치 만들기</h2>
          <button onClick={handleButtonClick}>
            무료 세미나 신청하기
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="7"
              viewBox="0 0 4 7"
              fill="none"
            >
              <path d="M0.5 1L2.5 3.5L0.5 6" stroke="white" strokeLinecap="round" />
            </svg>
          </button>
        </AdText>
        <CloseButton onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <circle cx="7" cy="7" r="7" fill="#F3F3F3" />
            <path d="M8.84241 5.15771L5.1582 8.84193" stroke="#999999" strokeWidth="0.736842" />
            <path d="M8.8418 8.84193L5.15759 5.15771" stroke="#999999" strokeWidth="0.736842" />
          </svg>
        </CloseButton>
      </AdContent>
    </AdPopupContainer>
  );
};

AdPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AdPopup;
