import styled from "@emotion/styled";

const AdPopupContainer = styled.div`
  width: 100%;
  background: #fff;
  position: relative;
`;

const AdContent = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 28px;
  gap: 14px;
`;

const AdImage = styled.img`
  width: 158px;
  height: auto;
  cursor: pointer;
`;

const AdText = styled.div`
  flex: 1;
  word-break: keep-all; // 단어 단위 줄넘김 설정

  p {
    color: #a0a0a0;
    font-size: 10px;
    font-weight: 700;
  }

  h2 {
    color: #464646;
    font-size: 12px;
    line-height: 126%; /* 15.12px */
    margin: 2px 0 5px;
  }

  button {
    border-radius: 2px;
    background: #111;
    color: #fff;
    border: none;
    padding: 7px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 100%;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

export { AdPopupContainer, AdContent, AdText, CloseButton, AdImage };
